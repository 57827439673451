@font-face {
    font-family: firasans-bold;
    src: url(../Fira_Sans/FiraSans-Bold.ttf);
  }

@font-face {
    font-family: firasans-italic;
    src: url(../Fira_Sans/FiraSans-Italic.ttf);
}

@font-face {
    font-family: firasans;
    src: url(../Fira_Sans/FiraSans-Regular.ttf);
}

@font-face {
    font-family: firasans-light;
    src: url(../Fira_Sans/FiraSans-Light.ttf);
  }

@font-face {
    font-family: firasans-lightitalic;
    src: url(../Fira_Sans/FiraSans-ExtraLightItalic.ttf);
}

@font-face {
    font-family: firasans-medium;
    src: url(../Fira_Sans/FiraSans-Medium.ttf);
}

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

:root{
    --default-color: #112947;
    --accent-color: #31cde6;
    --background-color: #f9f9f985;
}

body{
    
    
    height: 100vh;
    background-color: var(--background-color);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
}

.home-body {
    background: none;
    height: 100vh;
    background-color: var(--background-color);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
}

.mt-6{
    margin-top: 4rem !important;
}

.mt-7{
    margin-top: 5rem !important;
}

.mt-8{
    margin-top: 6rem !important;
}

.main-content{
    /*text-shadow: 2px 2px 15px black;*/
    margin-bottom: 10vh;
}

/* Center the splash text horizontally and vertically on page */
.main-content h1{
    font-family: firasans-bold, sans-serif;
    font-weight: 700 !important;
    letter-spacing: 0.01em;

}

.main-content h2{
    font-family: firasans, sans-serif;
}

.main-content h3{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: firasans-lightitalic, sans-serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.main-content > p{
    font-family: firasans-italic;
}

.splash {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    margin-left: 10px;
    margin-right: 10px;
}

.about {
    margin-bottom: 10%;
    font-family: firasans-light;
    color: black;
    min-height: 100%;
    max-width: 95%;
    background-color: #f9f9f9;
    font-size: 1.25rem;
    margin-right: 10%;
    margin-left: 10%;
}

.landingServices {
    margin-top: 4%;
    margin-bottom: 10%;
    margin-right: 10%;
    margin-left: 10%;
    font-family: firasans-light;
    color: black;
    display: flex;
    flex-direction: row;
    background-color: #f9f9f9;
    font-size: 1.25rem;
    justify-content: space-between;
}

.pricing-column {
    padding-left: 0;
    padding-right: 0;
}

.landing-prices {
    
    background-color: white;
    border: 1px solid black;
    border-radius: 0.5rem;
    text-align: center;
}

.landing-prices-features {
    color: rgb(83, 83, 83);
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.landing-image {
    max-width: 80%;
    height: auto;
    margin: 1rem 1rem;
}

.landing-prices-features li{
    margin: 1rem auto;
    font-size: 1rem;
}

.landing-prices h3{
    color: black;
    font-family: firasans;
    margin-bottom: 40px;
    margin-top: 25px;
    position: relative;
    font-size: 1.5rem;
}

.services-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:flex-start;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
}

.services-container .services-column {
    box-sizing: border-box;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    background-color: white;
    padding: 2rem;
    cursor: pointer;
    border:1px solid rgba(0, 0, 0, 0.2);
    
}

.services-container .services-column.closed {
    box-sizing: border-box;
    flex: 1 1 auto;
    height: 45vh;
    overflow:hidden;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    background-color: white;
    padding: 2rem;
    padding-bottom: 4rem;
    cursor: pointer;
    border:1px solid rgba(0, 0, 0, 0.2);
    
    width: 100rem;

}

.services-container .services-column.closed .services-column-content {
    opacity: 0;
    height: 0;
}

.services-container .services-column.closed::after {
    position: absolute;
    z-index: 25;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 20%;
/*     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.45) 0, rgba(255, 255, 255, 0.85) 40%, rgba(255, 255, 255, 1.0) 60%);
 */}

.services-container .services-column.open {
/*     left: 50%;
    transform: translateX(-50%); */
    position: relative;
    max-width: 100rem;
    height: 70vh;
    width: 100rem;
    /*flex: 0 0 50%;*/
    padding: 1rem;
    margin: 1rem;
}

.second-container .services-column.open  {
    height: 90vh;
}

.services-container .services-column.open .card-icon {
    width: 45%;
    height: auto;
    padding: 0;
    margin-bottom: 0.5rem;
}

.services-container .services-column.open li {
    margin-left: 1rem;
}

.services-container .services-column .card-icon {
    width: 60%;
    height: auto;
    padding: 1rem;
    margin-bottom: 0.5rem;
}

.services-container .services-column h3:first-of-type {
    font-family: firasans;
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.services-container .services-column h4:first-of-type {
    font-size: 1.25rem;
    font-family: firasans-light;
}

.services-column p:first-of-type {
    position:absolute;
    bottom: 1rem;
    left: 0;
    height: 1rem;
    text-align: center;
    width: 100%;
    z-index: 30;
    box-sizing: border-box;
}


.services-container .services-column h5.text-muted{
    font-family: firasans-lightitalic;
    font-size: 1rem;
}

.services-container .services-column h5.hidebar:last-of-type {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}


.services-container .services-column .btn-services {
    /*background: linear-gradient(to left, #32A1AA,#00F9FF)
     background-color: pink;
 */
    position: absolute;
    bottom: -1.25rem;
    right: 0;
    left: 0;
    background-color: var(--accent-color); /* 33e3ff alternate */ 
    /* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5); */
    width: 8em;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 0 auto 0 auto;
    height: 3em;
    text-align:center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background-size: 300% 100%;
    
}

.services-container .services-column li {
    font-size: 1rem;
    list-style: none;
    text-align: left;
    margin: 0 0 1rem 0;
}

.services-container .services-column li ol li {
    margin-top: 1rem;
}


.services-container .services-column li::before {
    /*
    The desired width gets defined in two places: The element width, and background size.
    The height only gets defined once, in background size.
    */
    position: absolute;
    display: block;
    content: '\2022'; /* bullet point, for screen readers */
    text-indent: -999999px; /* move the bullet point out of sight */
    left: 2.5em;
    width: 2em; /* desired width of the image */
    height: 2em; /* unrelated to image height; this is so it gets snipped */
    background-repeat: no-repeat;
    background-image: url("../images/checkmark.svg");
    background-size: 1em 1em;
    background-position: 0 .3em;  
}

.services-container .services-column li ol li::before {
    position: absolute;
    display: block;
    content: '\2022'; /* bullet point, for screen readers */
    text-indent: -999999px; /* move the bullet point out of sight */
    left: 5em;
    width: 2em; /* desired width of the image */
    height: 2em; /* unrelated to image height; this is so it gets snipped */
    background-repeat: no-repeat;
    background-image: url("../images/checkmark.svg");
    background-size: 1em 1em;
    background-position: 0 .3em;
}

#services2::before {
    position: absolute;
    top: 0;
    right: 0;
    white-space: pre;
    content: "*Available after a\00000afoundations session";
    font-style: italic;
    text-align: right;
    font-size: calc(5px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));;
    margin: 0.75rem;
    color: rgba(255, 0, 0, 0.61);
}


Button.splashButton {
    color: white;
    padding: .375rem .75rem;
    border-style: solid;
    border-radius: 1px;
    font-family: firasans-light;
    font-size: 24px;
    letter-spacing: 0.1rem;
    background-color: RGBA(0,0,0,0);
    border-color: white;
    display: flex;
    align-items: center;
    will-change: transform;
}

.splashCard{
    color: white;
    background-color: rgba(17,41,71,0.5);
    max-width: 300px;
}

.pic-container {
    margin: 1em;
    margin-left: 5em;
    float: right;
    width: 25vw;
}

.circlePic {
    display: block;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    max-width: 80%;
    margin-bottom: 7%;
/*     margin-bottom: 10%;
    margin-top: 10%;
    margin-right: 0%; */
    filter: drop-shadow(30px 10px 5px #112947);
    -webkit-filter: drop-shadow(10px 10px 5px #112947);
}

.aboutHeader {
    margin-top: 5%;
    
    font-family: firasans-medium;
    font-size: 3rem;
}

/* .aboutContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

.aboutPara {
    margin-top: 1%;
    margin-right: 8%;
    font-size: 0.90em;
    color: #565656;
    width: 70%;
}

h1.text-left {
    font-family: firasans-medium;
}

h1.text-center{
    font-family: firasans-medium;
}

.checkmark-container {
    display: flex;
    margin-top: 1.5rem;
    flex-direction: column;
    font-size: 1em;
    padding-bottom: 3rem;
}

.checkmark-container .text-left{
    /* maybe play with font weight of checkbox text */
    margin-bottom: 0.5rem;
}

svg.check-scaled {
    margin-right: 0.75rem;
}

p.text-left {
    font-family: firasans-light;
    color: #565656;
}

.check-text {
    
}

@media only screen and (min-width: 1800px) {
    .pic-container {
        margin-top: -1em;
    }
}

@media only screen and (min-width: 1200px){
    .card-icon{
        width: 80%;
    }

}
@media only screen and (max-width: 900px) {
    .aboutHeader{
        text-align: center;
    }
}

@media only screen and (max-width: 1200px) {
    .pic-container {
        width: 100%;
        margin: 0;
    }

    .circlePic {
        max-width: 75%;
        margin: 0 auto 1em auto;
    }

    .aboutPara {
        margin-right: 0;
        width: 100%;
    }

    svg.check-scaled {
        width: 32px;
        height: 32px;
        float: left;
    }

    .check-text {
        margin-top: 0;
    }

    #aboutDest > div > div > div.aboutContainer > p > div > p:nth-child(3) > svg,
    #aboutDest > div > div > div.aboutContainer > p > div > p:nth-child(4) > svg {
        margin-top: 0.5rem;
    }

    .services-container {
        flex-wrap: wrap;
        flex-basis: 1;
    }

    .services-container .services-column.closed {
        height: 100%;
    }

    .services-column.closed .services-column-content {
        opacity: 0;
        height: 0;
        flex-basis: 0;
        transition: opacity 0.5s, height 0.5s, flex-basis 0.5s;
    }

    .services-column.open .services-column-content{
        opacity: 1;
        display: block;
        transition: opacity 1s, height 0.5s, flex-basis 0.5s;
    }

    #services2::before {
        font-size: 12.5px;
    }

    .services-container .services-column {
        min-width: 100%;
        width: 100%;
    }

    .main-content {
        margin-bottom: 15vh;
    }

    .main-content h1.text-center {
        font-size: 3em;
    }

    .main-content h2.text-center {
        font-size: 2em;
    }

    .main-content h3.text-center {
        min-height: 84px;
        font-weight: 800;
        font-size: 20px;
    }
}