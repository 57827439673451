@font-face {
  font-family: firasans-light;
  src: url(../Fira_Sans/FiraSans-Light.ttf);
}

body {
  padding-top: 2rem;
  
}

.navbar {
  -webkit-transition: background-color 1s ;
  -moz-transition: background-color 1s ;
  -ms-transition: background-color 1s ;
  -o-transition: background-color 1s ;
  transition: background-color 1s ;
  max-height: 6rem/* 6rem */;
  background-color: rgba(17, 41, 71, 1) !important;

}

.nav-links{
  margin-bottom: 0.3rem;
}

.navbar-transparent {
  background-color: rgba(17, 41, 71, 0) !important;
}

.ml-auto.vertical-nav.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0;
  padding-top: 10vh;
  padding-bottom: 10vh;
  margin-top: 0;
  margin-bottom: 0;
  background-color: rgba(17, 41, 71, 1);
  position: absolute;
  top: 0;
  left: 0;
  animation: fadein 0.5s;
  font-size: 2.5vw;
}

.navbar-dark .navbar-toggler {
  color: rgba(255,255,255,1.0);
  border: none;
  flex-wrap: nowrap;
  position: absolute;
  top: 0;
  right: 0;
  padding: 2rem;
}

.navbar-toggler .navbar-toggler-icon {
  width: 2.0em;
  height: 2.0em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='-2 -2 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1.0%29' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' d='M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z'/%3e%3c/svg%3e");
  transition: background-image 0.5s;
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  width: 2.0em;
  height: 2.0em;
  transition: background-image 0.5s;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1.0%29' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.vertical-nav .active .dom-link.mobile-underline{
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 6px !important;
}

.vertical-nav .underline {
  display: none;
}

@keyframes fadein2 {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  100%   { opacity: 1; }
}


.collapsing {
  display: none;
  transition: none;
}

.navbar-toggler {
  z-index: 100;
}

.nav-link {
  z-index: 15;
  font-family: firasans-light;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 1.25em;
  color: white /* !important */;
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  text-decoration: none;
  
}

.navbar-transparent .nav-link {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.nav-link:hover{
  text-decoration: none !important;
}

.nav-link a{
  position: relative;
  display: block;
  text-decoration: none;
  color: #ecf0f1;
  text-transform: uppercase;
  padding: 4px 0;
  transition: 0.5s;
  cursor: pointer;
}

.nav-link a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;

}

.nav-link a:hover {
  color: #FFFFFF;
}

.nav-link a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.dom-link { 
  color: white /* !important */;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dom-link:hover{
  color:white;
  text-decoration: none;
}

.navbar-brand {
  color: white !important;
}

.slide-enter {
  transform: translateY(-100%);
  transition: .3s cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter.slide-enter-active {
    transform: translateY(0%);
}

.siteLogo {
  width: 100%;
  max-width: 270px;
  height: auto;
}

.slide-exit {
  transform: translateY(0%);
  transition: .3s ease-in-out;
}
.slide-exit.slide-exit-active {
  transform: translateY(-100%);
}
.dropdown .dropdown-menu {
  -webkit-transition: .5s cubic-bezier(0, 1, 0.5, 1);
  -moz-transition: .5s cubic-bezier(0, 1, 0.5, 1);
  -ms-transition: .5s cubic-bezier(0, 1, 0.5, 1);
  -o-transition: .5s cubic-bezier(0, 1, 0.5, 1);
  transition: .5s cubic-bezier(0, 1, 0.5, 1);

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown-menu.show {
  max-height: 200px;
  opacity: 1;
}

.horizontal-nav{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.underline {
  /* fix box width */
  width: 40%;
  height: 3px;
  box-sizing: border-box;
  border-radius: 0px;
  background: black;
  /* Remember: relative/absolutely positioned nodes 
  will be used in reference with each other for calculations.
  
  Since the navbar nav-link is absolute (not relative),
  use absolute positioning on the underline for the right
  width % calculation. */
  position: absolute;
  bottom: 15px;
  z-index: 10;
}

.no-underline {
    /* set invisible underline */
    width: 0;
    height: 3px;
    border-radius: 0px;
    background: black;
    position: absolute;
    bottom: 15px;
    z-index: 5;
}

@media only screen and (max-width: 600px) {
  .nav-link{
    font-size: 8vw;
  }
  .ml-auto.vertical-nav.nav-links {
    padding: 15vh 0 15vh 0;
  }
}

@media only screen and (min-width: 992px) {
  .nav-link{
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 1500px) {
  .nav-link{
    font-size: 1.25em;
  }
}

