@font-face {
  font-family: firasans-bold;
  src: url(../Fira_Sans/FiraSans-Bold.ttf);
}

@font-face {
  font-family: firasans-italic;
  src: url(../Fira_Sans/FiraSans-Italic.ttf);
}

@font-face {
  font-family: firasans;
  src: url(../Fira_Sans/FiraSans-Regular.ttf);
}

@font-face {
  font-family: firasans-light;
  src: url(../Fira_Sans/FiraSans-Light.ttf);
}

@font-face {
  font-family: firasans-regular;
  src: url(../Fira_Sans/FiraSans-Regular.ttf);
}

@font-face {
  font-family: firasans-medium;
  src: url(../Fira_Sans/FiraSans-Medium.ttf);
}

@font-face {
  font-family: firasans-mediumitalic;
  src: url(../Fira_Sans/FiraSans-MediumItalic.ttf);
}

@keyframes partners-fadein {
  0%{ opacity: 0; transform: translate(0, 50px); }
  100%{ opacity: 1; transform: translate(0, 0) }
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused;
}

.approach body {
  background: none;
  background-color: var(--background-color);
}

:root {
  --default-color: #112947;
  --accent-color: #31cde6;
  --background-color: #f9f9f9;
}

html {
  background-color: var(--background-color);
  font-size: 100%;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.splash {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 10px;
  margin-right: 10px;
}

a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: black;
}

.approach {
  margin-top: 4rem;
  padding-bottom: 0rem;
  font-family: firasans-light;
  justify-content: space-between;
  overflow: hidden;
  color: black;
  background: none;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  /*     background-color: var(--background-color) !important;
 */
  font-size: .9375rem;
}

.approach-wrapper {
  /* background-color: var(--background-color); */
}

.approach-body {
  height: 100%;
  margin-bottom: 0;
  background: none !important;
}

.approach-body::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  max-height: 100vh;
  background: url("../images/massimo-negrello-S7WoaCSkOvo-unsplash.jpeg");
/*  background: url("../images/kinga_axel_flipped.png"); */
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  z-index: -1;
}

.approach-header {
  font-size: 2.5em;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 3rem;
}

.approach-header-section {
  height: 90vh;
  min-width: 100%;
}

.approach-header h1 {
  color: white;
}

.approach-header h4 {
  color: white;
}

.approach-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: initial;
  flex-flow: wrap;
  margin-left: 2rem;
  float: left;
  max-width: 90vw;
}

.approach-title {
  font-family: firasans-regular;
  font-size: 2em;
  color: white;
  transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.approach-subtitle {
  font-size: 1.2em !important;
  color: white;
  margin-bottom: 1.25rem !important;
  transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#servicesDest,
#partnersDest,
#testimonialsDest,
#contactDest
{
  margin-top: 6rem;
  color:black;
}

.services-sub{
  font-size: 0.90em;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.paw-container {
  position: relative;
}

.paw-class {
  width: 5%;
  height: 5%;
}

.page-wrapper {
  height: 100%;
  width: 100vw;
  background-color: var(--background-color);
  color: black;
  margin-top: 5rem;
  overflow: hidden;
}

.partners-body {
  font-family: firasans-medium;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  min-height: 42rem;
  width: 100vw;
  max-height: 100vh;
  background-image: none;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.contact-wrapper {
  height: 100%;
  width: 100vw;
  background-color: var(--background-color);
  color: black;
  margin-top: 2rem;
  overflow: hidden;
}

.contact-container {
  min-height: 100%;
  max-width: 40vw;
  padding: 1rem 1rem 1rem 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.form-label {
  font-family: firasans-light;
  font-size: 1.5em;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #444;
}

.contact-container .contact-title {
  color: black;
}

.ksubheader {
  margin-top: 1rem;
  font-family: firasans-light;
  color: #444;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.contact-body {
  height: 100%;
  margin-bottom: 0;
  background-image: none;
}

.contact-body .recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.contact-container .form-control {
  background-color: white;
  border-radius: 0;
  border-color: rgb(197, 197, 197);
  resize: none;
  padding-right: 0;
  font-family: firasans-light;
  font-size: 1rem;
  color: #888;
}

#contact-form .success {
  position: relative;
  background-color: white;
  padding: 1rem;
  display: inline-block;
  margin: 1rem 0rem 1rem 0rem;
  border-left: 10px solid rgba(0, 143, 7, 1); 
  font-family: firasans-light;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: auto;
}

#contact-form .success .check-container{
  width: 5rem;
  height: 5rem;
  margin: 0 0 0 1rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  bottom: 0;
}
#contact-form .success .text-container{
  margin-left: 6rem;
}

#contact-form .success .check-container .green-checkmark{
  width: 5rem;
  height: 5rem;
  position: relative;
  top: 0px;
}

select#subject.form-control{
  display: block;
  padding-left: 0.5rem;
}

.contact-container form {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.partners-container {
  min-height: 100%;
  max-width: 100vw;
  font-size: 2.5em;
  padding: 0rem;
  margin-left: auto;
  margin-right: auto;
}

.partners-container .partners-logos {
  z-index: 1;
}

.partners-container .partners-logos ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  box-sizing: border-box;

  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-left: 2rem;
  padding-right: 2rem;
}

.partners-container li {
  height: 40vh;
  flex-grow: 1;
  margin: 3rem 0.5rem 0.5rem 0.5rem;
}

.partners-container .logo-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  
}

.partners-container .partners-logos ul li {
  opacity: 0;
  animation: 2s ease 0s normal forwards 1 partners-fadein;
}

/* SCSS would have been faster */

.partners-container .partners-logos ul li:nth-child(1) {
  animation-delay: 1s;
}

.partners-container .partners-logos ul li:nth-child(2) {
  animation-delay: 1.5s;
}

.partners-container .partners-logos ul li:nth-child(3) {
  animation-delay: 2s;
}

.partners-container .partners-logos ul li:nth-child(4) {
  animation-delay: 2.5s;
}

.partners-container .partners-logos ul li:nth-child(5) {
  animation-delay: 3s;
}

.partners-container .partners-logos ul li:nth-child(6) {
  animation-delay: 3.5s;
}

.partners-container .partners-logos ul li:nth-child(7) {
  animation-delay: 4s;
}

.logo-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  color: #FFF;
  background: rgba(0, 0, 0, .6);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.4);
  transition: opacity 0.75s ease;
}

.logo-container:hover .after {
  opacity: 1;
  background: rgba(0, 0, 0, .6);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.4);
  z-index: 2;
  cursor: pointer;
}

.partners-container li:last-child {
  margin-bottom: 20vh;
}

.partners-container img {
  max-height: 100%;
  min-width: 100%;
  object-fit: contain;
  vertical-align: bottom;
  transition: box-shadow 1s;
  background-color: white;
}

.partners-container img.landscape {
  max-width: 25vw;
}

.testimonials-blurb {
  text-align: center;
  font-family: firasans-italic;
  color: #666666;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: relative;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

/* When you mouse over the container, fade in the overlay title */
.container:hover .overlay {
  opacity: 1;
}

/* .approach-section ol li:before {
  counter-increment: li;
  content: counter(li) ". ";
  color: var(--accent-color);
  font-family: FiraSans-mediumitalic;
  font-size: 1.5em;
  margin-bottom: 3em;
} */

.approach-section {
/*   display: flex;
  align-items: flex-start;
  flex-direction: row; */

}

.approach-section .approach-section-text {
/*   display: flex;
  flex-direction: column;
  align-items: flex-start; */
  word-wrap: normal;
}

.approach-section b {
  font-family: firasans-regular;
}

.approach-section p {
  font-size: 1.16em;
  align-self: stretch;
}

.approach-section h1.text-left{
  font-size: 2.5rem;
}

.approach-section .approach-pic-cropped-kinga {
  height: auto;
  width: 30%;
  margin-bottom: 5%;
  margin-left: 5%;
  border-radius: 5px;
  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.3));
  float: right;
}

.container .obedience p {
  font-size: 1.16em;
}

.obedience-motion-div {
  background-color: var(--background-color);
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 7%;
}

.behavior-motion-div {
  background-color: var(--background-color);
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
}

.commands-motion-div {
  background-color: var(--background-color);
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
}

.approach-section .approach-pic-cropped {
  height: auto;
  max-width: 35%;
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.3));
  float: right;
}

.approach-section .approach-pic-cropped-left {
  height: auto;
  max-width: 30%;
  margin-left: 0;
  margin-right: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
  filter: drop-shadow(-10px 10px 5px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(-10px 10px 5px rgba(0, 0, 0, 0.3));
  float: left;
}

.approach-card {
  background-color: rgba(249, 249, 249, 0) !important;
  width: 20vw;
  border-radius: 5px;
  border: 2px solid white !important;
  backdrop-filter: blur(10px);
  height: 10rem !important;
  margin: 1em !important;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  /*   max-height: 5em;
  overflow: hidden; */
}

.approach-card::after {
  background-color: rgba(249, 249, 249, 1) !important;
  content: "";
  border-radius: 0px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.9); */
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.approach-card:hover {
  transform: scale(1.05);
  border: none;
  transform: translateY(-7px);
}

.approach-card:hover .approach-title,
.approach-card:hover .approach-subtitle,
.approach-card:hover .approach-learn {
  color: black;

}

.approach-card:hover::after {
  opacity: 1 !important;
}

.approach-card .approach-learn {
  color: var(--background-color);
  transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;
  bottom: 0.25em;
  right: 5em;
  stroke: white;
  stroke-width: 1;
}

.step-no {
  position: absolute;
  bottom: 1.20em;
  right: 0.75em;
  stroke: white;
  stroke-width: 1;
  transition: stroke 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.approach-card:hover .step-no {
  user-select: none;
  stroke: black;
}

.behavior {
  padding-right: 5px;
  padding-left: 5px;
}

.container .obedience {
  margin-bottom: 5rem;
}

.container .commands {
  margin-top: 5rem;
}

.container .commands li{
  vertical-align: top;
  margin-bottom: 1em;
}

.container .commands li::marker {
  color: var(--accent-color);
  font-family: FiraSans-mediumitalic;
  font-size: 1.0em;
}
.approach-section ol {
  margin-left: 0;
  padding-left: 2em;
  list-style-position: outside;
}

.container .behavior {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.fadeout {
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
}

.testimonials-body {
  overflow-x: hidden;
  box-sizing: border-box;
  content: '';
  position: absolute;
  background: var(--background-color) !important;
  left: 0;
  top: 0;
  min-height: 42rem;
  width: 100vw;
  max-height: 100vh;
  background-image: none;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.testimonials-box {
  width: 40rem;
  padding: 2rem;
  margin-right: auto;
  flex-basis: 55%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  /* font-family: firasans-light; */
}

/*
  Blockquote CSS by Juan Pablo on Codepen
  https://codepen.io/jupago/pen/dwZKbM
*/


/* center the blockquote in the page */
.blockquote-wrapper {
  display: flex;
  height: 100vh;
  padding: 0 20px;
}

#testimonialsDest > div:nth-child(2) > div.blockquote{
  margin: 1rem auto 3rem auto;
}

/* Blockquote main style */
.blockquote {
   position: relative;
   font-weight: 800;
   color: #000000;
   padding: 30px 0;
   width: 100%;
   max-width: 70vw;
   z-index: 1;
   margin: 5rem auto 3rem auto;
   align-self: center;
   border-top: solid 1px;
   border-bottom: solid 1px;
}

/* Blockquote header */
.blockquote h2 {
   position: relative;
   color: #575757;
   font-size: 2rem;
   font-weight: 300;
   line-height: 1.4;
   margin: 0;
   font-family: Georgia;
   font-style: italic;
}

/* Blockquote right double quotes */
.blockquote:after {
   position: absolute;
   content: "”";
   color: var(--default-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 10rem;
   line-height: 0;
   bottom: -43px;
   right: 30px;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
   .blockquote h1 {
       font-size: 60px;
  }

}

/* Blockquote subheader */
.blockquote h4 {
   position: relative;
   color: #292a2b;
   font-size: 1.4rem;
   font-weight: normal;
   line-height: 1;
   margin: 0;
   padding-top: 20px;
   z-index: 1;
}


.my-card {
  position: relative;
  width: 40%;
  height: 40%;
  margin: 0 auto 2rem auto;
  /* transform: scale(0.5); */
  transform-origin: bottom;
}

.testimonial-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 90%;
}

.testimonial-full-container {
  width: 70%;
  align-self: center;
}

.testimonial-full-container:last-of-type {
  margin-bottom: 25vh;
}

.testimonials-header h1{
  font-size: 3rem;
}

.testimonials-header {
  text-align: center;
  padding: 0 0 1rem 0;
}
.testimonial-full-container {
  box-sizing: border-box;
  width: 100%;
}

.testimonials-image {
  /* Set width, height, object-fit: cover to crop image instead of resizing */
  width: auto !important;
  height: auto !important;
  object-fit: cover;
  
}

.testimonails-carousel-caption {
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  position: absolute;
  bottom: 1rem;
  right: 0;
  padding: 1rem;
  background-color:rgba(0, 0, 0, 0.8);
}

.testimonials-wrapper .carousel:nth-of-type(1)::after {
  content: "MK9s Service Dogs";
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding: 1rem;
  background-color:rgba(0, 0, 0, 0.8);
}

.testimonials-wrapper .carousel:nth-of-type(2)::after {
  content: "Lexi";
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding: 1rem;
  background-color:rgba(0, 0, 0, 0.8);
}

.testimonials-wrapper > div > div:nth-child(7) > div.testimonial-container > div.testimonials-my-carousel.carousel.slide::after {
  content: "Puppy Litter";
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding: 1rem;
  background-color:rgba(0, 0, 0, 0.8);
}

.testimonials-car-image {
  height: 75vh !important;

}

.testimonials-my-carousel {
  flex-basis:44%;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  height: auto;
}

.testimonials-wrapper {
  height: 100%;
  width: 100vw;
  background-color: var(--background-color);
  color: black;
  margin-top: 2rem;
  overflow: hidden;
}

.testimonials-text {
  font-size: 1rem;
}

.testimonials-title {
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  position: absolute;
  bottom: 1rem;
  right: 0;
  padding: 1rem;
  background-color:rgba(0, 0, 0, 0.8);
}

#contact-form > div.recaptcha > div > div > div{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

/* 


    FRAMER MOTION CARD EXAMPLE CSS


 */
/* 
.testimonialsDest #root {
  display: flex;
  justify-content: center;
}

.testimonialsDest .screen {
  width: 100%;
  height: 100%;
}

.testimonialsDest .container {
  max-width: 990px;
  flex: 1 1 100%;
  padding: 45px 25px;
}

.testimonialsDest h1 {
  font-weight: bold;
  color: white;
  margin: 6px 0 12px;
}

.testimonialsDest .date {
  color: var(--secondary);
  font-size: 14px;
  text-transform: uppercase;
}

.testimonialsDest header {
  border-bottom: 1px solid var(--divider);
  position: relative;
}

.testimonialsDest .avatar {
  background: var(--divider);
  border-radius: 50%;
  position: absolute;
  bottom: 12px;
  right: 0;
  overflow: hidden;
}

.testimonialsDest .avatar,
.testimonialsDest .avatar img {
  width: 40px;
  height: 40px;
}

.testimonialsDest ul,
.testimonialsDest li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.testimonialsDest .card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.testimonialsDest .card {
  position: relative;
  padding: 25px;
  height: 460px;
  flex: 0 0 40%;
  max-width: 40%;
}

.testimonialsDest .card:nth-child(4n + 1),
.testimonialsDest .card:nth-child(4n + 4) {
  flex: 0 0 60%;
  max-width: 60%;
}

.testimonialsDest .card:nth-child(odd) {
  padding-left: 0;
}

.testimonialsDest .card:nth-child(even) {
  padding-right: 0;
}

.testimonialsDest .card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.testimonialsDest .card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.testimonialsDest .card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 20px;
  background: #FFFFFF;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.testimonialsDest .open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
}

.testimonialsDest.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.testimonialsDest .card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 420px;
  width: 100vw;
  transform: translateZ(0);
}

.testimonialsDest .title-container {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
}

.testimonialsDest h2 {
  color: #fff;
  margin: 8px 0;
}

.testimonialsDest .category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.testimonialsDest .overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 990px;
}

.testimonialsDest .overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.testimonialsDest .content-container {
  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;
}

.testimonialsDest p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}

@media only screen and (max-width: 800px) {
  .card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    flex: 1 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .card-content-container.open {
    padding: 0;
  }
}
 */

/* Bootstrap */
.card {
  position: relative;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group>.card {
  margin-bottom: 0.75rem;
}

@media (max-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }

}

@media only screen and (max-width: 800px) {
  .approach-header.container {
    margin: 0;
  }
  .approach-row.container {
    margin: 0;
    max-width: 100vw;
    padding: 0;
  }
  .approach-card.card {
    width: 35vw;

  }
  .approach-body::before{
    max-height: 300vh;
    width: 200vw;
    min-height: 136vh;
  }
  .obedience-motion-div {
    margin-top: 30vh;
  }
  .approach-section .approach-section-text h1.text-left{
    font-size: 2rem !important;
  }
  .approach-section-text p {
    font-size: 1.25rem !important;
  }
  .approach-title{
    font-size: 1.25em;
  }
  .approach-learn{
    margin-left: 1rem;
  }
  .approach-row{
    justify-content: center;
  }
  .partners-container{
    margin: 0;
    padding: 0;
  }
  .partners-container .partners-logos ul{
    width: 100vw;
  }
  .services-sub {
    width: 100%;
  }

}


@media only screen and (min-width: 401px) {
  .contact-container{
    max-width: 90vw;
  }
}


@media only screen and (max-width: 1299px) {
  .approach-title{
    font-size: 1.45em;
  }
  .approach-learn{
    margin-left: 1rem;
  }
  .blockquote h2{
    font-size: 1rem;
  }
  .testimonial-container{
    display: block;
    flex-direction: column;
    width: 100%;
    /*margin-left: 0;*/
  }
  .testimonials-box{
    display: flex;
    width: 90%;
    padding: 2rem 0 2rem 0;
  }

  .testimonials-title {
    font-size: 0.25em;
  }

  .testimonials-wrapper{
    max-width: 99%;
    margin-left: 1rem;
  }
  .testimonials-header{
    padding: 1rem 0 1rem 0;
  }
  .testimonials-car-image{
    max-width: 99%;
    height:auto !important;
  }

  .testimonials-my-carousel {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .blockquote{
    margin: 11vw auto 8rem auto;
    max-width: 90vw;
  }
  .my-card{
    width: 99%;
    height: auto !important;
    padding: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .approach-card.card{
    width: 25vw;
  }

}

@media only screen and (min-width: 1400px) {
  .approach-card.card{
    width: 25vw;
  }
  .approach-row {
    max-width:60vw;
  }
  .contact-container{
    max-width: 40vw;
  }

}

@media only screen and (min-width: 1600px){
  .approach-row {
    max-width:60vw;
  }
  .blockquote h2{
    font-size: 2rem;
  }

}
